.App {
  text-align: center;
}

.Instructions {
  text-align: left;
}

.Caption {
  text-align: left;
}

.FormControlLabel {
  font-size: 0.875rem;
}

.App-logo {
  pointer-events: none;
}

.Moon-image {
  height: 40vmin;
}

.Gallery {
  text-align: center;
  max-height: 30%;
}

.image-gallery-slide img {
    width: 100%;
    height: 70vh;
}

.image-gallery-thumbnail-image {
    width: 50%;
    height: 50%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  max-height: 5vh;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.Msg-form{
  margin: 1em;
  padding: 2em;
}

.Msg-grid{
  margin: 1em
}

.Msg-output {
  font-size: 1em
}

.Dev-output {
  font-size: 1em;
  text-align: left;
  min-height: 20vh;
  min-width: 80vw;
}

.MuiInputBase-root {
  color: white;
  background-color: white;
}

.fix-grid {
  display: grid;
  grid-template: repeat(6, 1fr) / repeat(7, 1fr);
  background: #fff;
  grid-gap: 2px;
  min-height: 0;  /* NEW */
  min-width: 0;   /* NEW; needed for Firefox */
}

.footer {
 position: absolute;
 bottom: 0;
 width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
